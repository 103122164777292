import React from 'react'

export default function PersonFacts(props) {
    return (
        <>
         <h3>Fun Facts</h3>
                <article className="funFacts">
                    <p><strong>Fav Tech:</strong> {props.favoriteTech}</p>
                    <p><strong>Fav Food:</strong> {props.favoriteFood}</p>
                    <p><strong>Fav TV Show:</strong> {props.favoriteShow}</p>
                </article>   
        </>
    )
}
