import React from 'react'

export default function PersonQuote(props) {
    return (
        <>
           <h3>Favorite Quote</h3>
                <p className="quote">
                    <em>"{props.favoriteQuote}"</em><br/>
                    <span>~{props.favQuoteAuthor}~</span>
                </p>  
        </>
    )
}
