import React from 'react'

export default function PersonIntro(props) {
    return (
        <div className="intro">
            <h2>{props.name}</h2>
                <img src={props.image} alt="My ugly mug!" />
                <p className="elevatorPitch">{props.elevatorPitch}</p> 
                <div className="clear"></div> 
        </div>
    )
}
