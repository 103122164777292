import React, { useState } from 'react'
import './Profile.css'
import image from '../../images/BannerImageSquare.jpg'
import PersonIntro from './PersonIntro'
import PersonFacts from './PersonFacts'
import PersonQuote from './PersonQuote'

export default function Profile() {
    const [person, setPerson] = useState({
        name: 'James Caldwell',
        elevatorPitch: 'Welcome to my site! I have been a full stack web developer for over 3 years, creating multiple sites in different technologies. In the past year, I have increasingly used ReactJS, creating my own React portfolio. Woot, woot...React is fun!',
        favoriteTech: 'ReactJS',
        favoriteFood: 'Sushi',
        favoriteShow: 'Brooklyn Nine Nine',
        favoriteQuote: "Dreams don't work unless you do",
        favQuoteAuthor: 'John Maxwell'
    })
    return (
        <section className="profile">
            <div className="profileCard">
                <PersonIntro name={person.name} elevatorPitch={person.elevatorPitch} image={image} />
                <PersonFacts favoriteTech={person.favoriteTech} favoriteShow={person.favoriteShow} favoriteFood={person.favoriteFood} />
               <PersonQuote favoriteQuote={person.favoriteQuote} favQuoteAuthor={person.favQuoteAuthor} />
            </div>
        </section>
    )
}
